<template>
  <v-list
    class="px-2"
    :lines="false"
    density="compact"
    open-strategy="single"
    nav
  >
    <template v-for="(item, i) in nav" :key="i">
      <nav-group v-if="isGroup(item)" :item="item" />
      <nav-item v-else :item="item" />
    </template>
  </v-list>
</template>

<script lang="ts">
import type { RouteLocationRaw } from "vue-router";
import { isGroup, NavItem, NavGroup } from "../private/app-navigation";

export default defineComponent({
  components: { NavItem, NavGroup },
  setup() {
    const config = useAppConfig();
    const nav = computed(() => config.app.settingsNav);

    return {
      nav,
    };
  },
  methods: {
    routeTo(to: RouteLocationRaw) {
      this.$router.push(to);
    },
    isGroup,
  },
});
</script>
